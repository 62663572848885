const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5f64c2915d67e962474b218f',
    authMode: [
      'local',
    ],
    authLocalOptions: {
      mode: 'otp',
    },
    metadatas: {
      name: 'Mediameeting, Le Club',
      colorPrimary: '#e84b23',
      colorAccent: '#e34a4c',
      colorSecondary: '#0D0D0D'
    },
    analytics: {
      matomo: '31'
    },
    nuxt: {
      head: {
        title: "Mediameeting, Le Club",
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Retrouvez vos podcasts sur Le Club`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: "Le Club",
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'Le Club',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `Retrouvez vos podcasts sur Le Club`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '606f0c611a0171117c45506a',
            i18n: {
              fr: {
                title: 'Le podcast',
              },
            },
            heading: {
              color: '#eb5c26',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/podcast.png')
                  : require('~/assets/images/podcast.png')
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '606f14031a01718b91455076',
            },
          },
          {
            type: 'tag',
            tagId: '606f0c6f1a01713c6e45506b',
            i18n: {
              fr: {
                title: `L'Émission`,
              },
            },
            heading: {
              color: '#f6a130',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/emission.png')
                  : require('~/assets/images/emission.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '606f0c7aa42ea5659a20fe68',
            i18n: {
              fr: {
                title: `UE&O`,
              },
            },
            heading: {
              color: '#f9b233',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/ueo.png')
                  : require('~/assets/images/ueo.png')
              },
            },

          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
